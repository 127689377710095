import {
  Alert,
  Avatar,
  Badge,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { useRef, useState } from "react";

import DoctorFemIcon from "../../assets/images/med-fem.jpg";
import DoctorMascIcon from "../../assets/images/med-masc.jpg";
import { useAppointment } from "../../hooks/useAppointment";
import { IProfessionalSchedules, TimeType } from "../../types";
import { formatClinicAddress, formatName } from "../../utils/masks";
import { singularizeWithArticle } from "../../utils/roles";
import { HorizontalList } from "./styles";

interface IProfessionalScheduleCard {
  schedule: IProfessionalSchedules;
}

export const ProfessionalScheduleCard = ({
  schedule,
}: IProfessionalScheduleCard) => {
  const theme = useTheme();

  const {
    serviceItem,
    clinic,
    serviceGroup,
    date,
    professional,
    handleChangeAppointmentProfessional,
    handleChangeAppointmentStep,
    appointmentType,
  } = useAppointment();
  const horizontalListRef = useRef<HTMLDivElement>(null);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDown(true);
    setStartX(e.pageX - horizontalListRef.current!.offsetLeft);
    setScrollLeft(horizontalListRef.current!.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDown(false);
  };

  const handleMouseLeave = () => {
    setIsDown(false);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDown || !horizontalListRef.current) return;
    // e.preventDefault();
    const x = e.pageX - horizontalListRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Velocidade do scroll horizontal
    horizontalListRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setIsDown(true);
    setStartX(e.touches[0].pageX - horizontalListRef.current!.offsetLeft);
    setScrollLeft(horizontalListRef.current!.scrollLeft);
  };

  const handleTouchEnd = () => {
    setIsDown(false);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!isDown || !horizontalListRef.current) return;
    // e.preventDefault();
    const x = e.touches[0].pageX - horizontalListRef.current.offsetLeft;
    const walk = (x - startX) * 3; // Velocidade do scroll horizontal
    horizontalListRef.current.scrollLeft = scrollLeft - walk;
  };

  const onChangeTime = (
    professional: IProfessionalSchedules,
    date: string,
    vacancy: string | undefined,
    timeType: TimeType
  ) => {
    try {
      handleChangeAppointmentProfessional({
        professional,
        date,
        vacancy,
        dateSelected: new Date(date),
        timeType,
      });

      handleChangeAppointmentStep(2);

      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 200);
    } catch (error) {
      console.log(error);
    }
  };

  const isVacancy = schedule.type_vacancy;

  const totalAvailableSlots = schedule.timetable.filter(
    (time) => time.available === true
  ).length;

  const availableSlots = schedule.timetable.filter(
    (time) => time.available === true
  );

  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        borderColor: theme.palette.neutral.main,
        borderWidth: 1,
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid
            item
            lg={1}
            md={1}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Badge
              badgeContent={appointmentType}
              color={appointmentType === "particular" ? "primary" : "secondary"}
            >
              <Avatar
                src={
                  schedule.sexoprofissional?.toLowerCase() === "masculino"
                    ? DoctorMascIcon
                    : DoctorFemIcon
                }
                sx={{
                  width: 56,
                  height: 56,
                }}
                style={{
                  border: `0.1px solid ${theme.palette.primary.main}`,
                }}
                alt="professional profile"
              />
            </Badge>
          </Grid>
          <Grid item lg={11} md={11} sm={12} xs={12}>
            <Typography
              fontSize={24}
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontWeight: 600,
                mb: 1,
              }}
            >
              {formatName(schedule.nomeprofissional)}
            </Typography>

            <Stack direction="row" spacing={2}>
              {schedule.especialidades.map(
                (specialty) =>
                  specialty.idcategoriaservico ===
                    serviceItem?.idcategoriaservico && (
                    <Typography
                      key={specialty.idcategoriaservico}
                      fontSize={12}
                      sx={{
                        color: (theme) => theme.palette.secondary.main,
                        fontWeight: 600,
                      }}
                    >
                      {specialty.nome}
                    </Typography>
                  )
              )}
            </Stack>
            {schedule.numerodocumentoprofissional && (
              <Typography
                fontSize={12}
                sx={{
                  color: (theme) => theme.palette.onPrimaryContainer.main,
                  fontWeight: 500,
                }}
              >
                {`${
                  schedule.tipodocumentoprofissional?.toUpperCase() || "CRM"
                } - ${schedule.numerodocumentoprofissional}`}
              </Typography>
            )}
            {clinic && (
              <Stack mt={2}>
                <Typography
                  fontSize={12}
                  sx={{
                    color: (theme) => theme.palette.onPrimaryContainer.main,
                    fontWeight: 500,
                  }}
                >
                  Clínica: {clinic.nome}
                </Typography>
                <Typography
                  fontSize={12}
                  sx={{
                    color: (theme) => theme.palette.onPrimaryContainer.main,
                    fontWeight: 500,
                  }}
                >
                  Endereço: {formatClinicAddress(clinic)}
                </Typography>
              </Stack>
            )}
          </Grid>
        </Grid>

        <Divider
          sx={{
            mt: 2,
            mb: 2,
          }}
        />
        {serviceGroup &&
          serviceItem &&
          (isVacancy ? (
            <Typography
              fontSize={12}
              sx={{
                color: (theme) => theme.palette.onPrimaryContainer.main,
                fontWeight: 600,
              }}
            >
              O profissional atende por vagas, garanta já{" "}
              {singularizeWithArticle(
                serviceItem.categoriaservico.gruposervico
                  ? serviceItem.categoriaservico.gruposervico.nome.toLowerCase()
                  : serviceItem.gruposervico
                  ? serviceItem.gruposervico.nome.toLowerCase()
                  : serviceGroup.nome.toLowerCase()
              )}
            </Typography>
          ) : (
            <Typography
              fontSize={12}
              sx={{
                color: (theme) => theme.palette.onPrimaryContainer.main,
                fontWeight: 600,
              }}
            >
              Escolha o melhor horário para realizar{" "}
              {singularizeWithArticle(
                serviceItem.categoriaservico.gruposervico
                  ? serviceItem.categoriaservico.gruposervico.nome.toLowerCase()
                  : serviceItem.gruposervico
                  ? serviceItem.gruposervico.nome.toLowerCase()
                  : serviceGroup.nome.toLowerCase()
              )}
            </Typography>
          ))}
        {isVacancy && (
          <Grid container spacing={2} mt={0.1}>
            <Grid item lg={3} xs={12}>
              <Alert icon={false}>
                <Typography
                  fontSize={14}
                  sx={{
                    color: (theme) => theme.palette.onPrimaryContainer.main,
                    fontWeight: 600,
                  }}
                >
                  {`A partir das ${format(
                    new Date(
                      new Date(schedule.timetable[0].timeLabel.date)
                        .toISOString()
                        .slice(0, -1)
                    ),
                    "HH:mm"
                  )}`}
                </Typography>
                <Typography
                  fontSize={14}
                  sx={{
                    color: (theme) => theme.palette.onPrimaryContainer.main,
                    fontWeight: 400,
                  }}
                >
                  {totalAvailableSlots > 1
                    ? `${totalAvailableSlots} vagas disponíveis`
                    : `${totalAvailableSlots} vaga disponível`}
                </Typography>
              </Alert>
            </Grid>
            {availableSlots.length > 0 && (
              <Grid item lg={3} xs={12} display="flex" alignItems="center">
                <Button
                  size="large"
                  variant={
                    professional?.schedule.idagenda ===
                      schedule.schedule.idagenda &&
                    date === availableSlots[0].timeLabel.date
                      ? "contained"
                      : "outlined"
                  }
                  fullWidth
                  color="primary"
                  onClick={() => {
                    const vacancy = schedule.timetable.findIndex(
                      (item) =>
                        item.timeLabel.date === availableSlots[0].timeLabel.date
                    );
                    onChangeTime(
                      schedule,
                      availableSlots[0].timeLabel.date,
                      `Vaga ${vacancy + 1}`,
                      "vacancy"
                    );
                  }}
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Agendar uma vaga
                </Button>
              </Grid>
            )}
          </Grid>
        )}
        {!isVacancy && (
          <HorizontalList
            ref={horizontalListRef}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
          >
            <List>
              {availableSlots.map((item, index) => (
                <Button
                  key={index}
                  size="large"
                  variant={
                    professional?.schedule.idagenda ===
                      schedule.schedule.idagenda && date === item.timeLabel.date
                      ? "contained"
                      : "outlined"
                  }
                  color="primary"
                  disabled={!item.available}
                  onClick={() => {
                    onChangeTime(
                      schedule,
                      item.timeLabel.date,
                      undefined,
                      "time"
                    );
                  }}
                  sx={{
                    mr: 1,
                    minWidth: 120,
                  }}
                >
                  {format(
                    new Date(
                      new Date(item.timeLabel.date).toISOString().slice(0, -1)
                    ),
                    "HH:mm"
                  )}
                </Button>
              ))}
            </List>
          </HorizontalList>
        )}
      </CardContent>
    </Card>
  );
};
