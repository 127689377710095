export interface IScheduleByProfessionalResponse {
  idprofissional: string;
  nomeprofissional: string;
  idclinica: string;
  itensservico: IServiceItem[];
  schedules: IProfessionalSchedules[];
}


export type IProfessionalSchedules = {
  idprofissional: string;
  nomeprofissional: string;
  idclinica: string;
  clinica: {
    imagem_principal: {
      original: string;
      x256: string;
      x720: string;
    };
    imagem_capa: {
      original: string;
      x256: string;
      x720: string;
    };
    imagem_mapa: {
      original: string;
      x256: string;
      x720: string;
    };
    datacriacao: string;
    idclinica: string;
    nome: string;
  };
  especialidades: ProfessionalSpecialtyType[];
  schedule: {
    titulo?: string;
    itensservico: IServiceItem[];
    restricaoporservico?: any[];
    restricaoporconvenio?: any[];
    duracaoagendamentos: number;
    especialidades: string[];
    cancelado: boolean;
    idagenda: string;
    cronograma: {
      diasdasemana: string[];
      horarios: string[];
      limitepacientes: number;
    }[];
    excecao: boolean;
  };
  timetable: AvailableTimesProps[] | [];
  type_vacancy: boolean;
  sexoprofissional?: string;
  numerodocumentoprofissional?: string;
  tipodocumentoprofissional?: string;
};

type ProfessionalSpecialtyType = {
  datacriacao: string;
  idcategoriaservico: string;
  nome: string;
};

export type ProfessionalProps = {
  id: string;
  name: string;
  crm: string;
  profession: string;
  gender: string;
  availableTimes?: IProfessionalSchedules[];
  availableVacancies?: AvailableTimesProps[];
  address: string;
  urlPhoto: string;
};

export type AvailableTimesProps = {
  timeLabel: {
    date: string;
    times: string;
  };
  available: boolean;
  appointment: [];
};

export type TimeType = "time" | "vacancy" | "";

export type SelectedPaymentMethodType = "card" | "pix-wallet" | "unit" | "";

export type UserType = {
  idusuariopaciente: string;
  email: string;
  idpatient: string;
  paciente: {
    datacadastro: string;
    idpaciente: string;
    codigo: string;
    nomepaciente: string;
    numerocartao?: string;
    datanascimento: string;
    datanascimentotexto: string;
    cpf?: string;
    rg?: string;
    naturalidade?: string;
    descricaopacientefonte: string;
    tiposanguineo?: string;
    sexo?: string;
    fatorrh?: string;
    estadocivil?: string;
    escolaridade?: string;
    religiao?: string;
    nomecidade?: string;
    siglaestado?: string;
    logradouro?: string;
    numero?: string;
    complemento?: string;
    bairro?: string;
    cep?: string;
    telefoneresidencial: string;
    canalatendimento: {
      idcanalatendimento: string;
      nome: string;
      descricao: string;
      datacriacao: string;
      dataatualizacao: string;
    };
    email: string;
    telefonecelular: string;
    telefonecomercial?: string;
    sms?: string;
    nomeresponsavel?: string;
    rgresponsavel?: string;
    cpfresponsavel?: string;
    telefoneresponsavel?: string;
    nomeacompanhante?: string;
    rgacompanhante?: string;
    cpfacompanhante?: string;
    telefoneacompanhante?: string;
    idempresagestora?: string;
    doadororgaos?: string;
    doadorsangue?: string;
    peso?: string;
    altura?: string;
    profissao?: string;
    dependente?: string;
    fumante?: string;
    usoalcool?: string;
    sedentario?: string;
    lat?: string;
    lon?: string;
    sexualmenteativo?: string;
    ocupacao?: string;
    outrasdrogas?: string;
    observacaopaciente?: string;
    ativo: boolean;
    idlegado?: string;
    identificador: string;
    prioritario?: string;
    cadastrocanal?: string;
    idcanalatendimento: string;
    idcanalorigem: string;
  };
};

export type IClinic = {
  imagem_principal: {
    original: string;
    x256: string;
    x720: string;
  };
  imagem_capa: {
    original: string;
    x256: string;
    x720: string;
  };
  imagem_mapa: {
    original: string;
    x256: string;
    x720: string;
  };
  datacriacao: string;
  idclinica: string;
  cnpj: string;
  nome: string;
  responsaveltecnico: string;
  tipodocumentoresponsavel?: string;
  numerodocumentoresponsavel?: string;
  estado: string;
  cidade: string;
  bairro: string;
  logradouro: string;
  complemento: string;
  numero: string;
  cep: string;
  telefone: string;
  whatsapp: string;
  horarioatendimento: string;
  site: string;
  info: string;
  info_paciente: string;
  ativo: boolean;
  dataatualizacao: string;
};

export type CategoryServiceType = {
  datacriacao: string;
  idcategoriaservico: string;
  nome: string;
  descricao: string;
  ativo: boolean;
  agendamentoonline: boolean;
  agruparitens: boolean;
  idgruposervico: string;
  dataatualizacao: string;
  gruposervico: {
    datacriacao: string;
    idgruposervico: string;
    nome: string;
    descricao: string;
    ativo: boolean;
    agendamentoonline: boolean;
    dataatualizacao: string;
  };
};

export type IServiceItem = {
  iditemservico: string;
  nome: string;
  descricao: string;
  idcategoriaservico: string;
  idgruposervico?: string;
  iditemvinculado?: string;
  valor: string;
  descontoavista?: string;
  acrescimoparcelado: [];
  duracaoconsulta: number;
  preparo: string;
  ativo: boolean;
  agendamento: boolean;
  agendamentoonline: boolean;
  retorno: boolean;
  diaspararetorno: number;
  externo: boolean;
  encaminhamento: boolean;
  gratuito: boolean;
  prontuariosigiloso: boolean;
  destaque: boolean;
  oftalmologico: boolean;
  datacriacao: string;
  dataatualizacao: string;
  categoriaservico: {
    datacriacao: string;
    idcategoriaservico: string;
    nome: string;
    descricao: string;
    ativo: boolean;
    agendamentoonline: boolean;
    agruparitens: boolean;
    idgruposervico: string;
    dataatualizacao: string;
    gruposervico: {
      datacriacao: string;
      idgruposervico: string;
      nome: string;
      descricao: string;
      ativo: boolean;
      agendamentoonline: boolean;
      tipospagamentos: string;
      dataatualizacao: string;
    };
  };
  gruposervico: {
    datacriacao: string;
    idgruposervico: string;
    nome: string;
    descricao: string;
    ativo: boolean;
    agendamentoonline: boolean;
    tipospagamentos: string;
    dataatualizacao: string;
  };
};

export enum APPOINTMENT_STATUS {
  SCHEDULED = "agendado",
  CONFIRMED = "confirmado",
  SETTLED = "quitado",
  CHECK_IN = "checkin",
  ATTENDED = "atendido",
  CANCELLED = "cancelado",
  MISSED = "faltou",
  OPENED = "aberto",
}

export type AppointmentType = {
  idconsulta: string;
  idagenda: string;
  idpaciente: string;
  idprofissional: string;
  idusuario: string;
  data: string;
  duracao: number;
  status: string;
  prioritario: boolean;
  vacancy?: string;
  status_historico: {
    status: string;
    data: string;
    idusuario: string;
    nomeusuario: string;
  }[];
  idcanalatendimento: string;
  temretorno: boolean;
  datacriacao: string;
  dataatualizacao: string;
  paciente: IPatient;
  orderitems: {
    idorderitem: string;
    combo?: IServicePackage;
    plano?: ICategoryHealthPlan;
    idordemservico?: string;
    idagendamento: string;
    idpreordem?: string;
    idguiaencaminhamento?: string;
    iditemservico: string;
    idusuario: string;
    status: string;
    quantidade: number;
    valorbase: string;
    valoratual: string;
    iddesconto?: string;
    valordesconto: string;
    idacrescimo?: string;
    valoracrescimo: string;
    iditemplano?: string;
    idplano?: string;
    iditemcombo?: string;
    idcombo?: string;
    iditemorcamento?: string;
    idorcamento?: string;
    idpedidoexame?: string;
    idcheckin?: string;
    idprofissionalsolicitante?: string;
    datacriacao: string;
    dataatualizacao: string;
    itemservicos: IServiceItem;
  }[];
  agenda: {
    datacriacao: string;
    idagenda: string;
    titulo: string;
    idclinica: string;
    idprofissional: string;
    cronograma: {
      diasdasemana: string[];
      horarios: string[];
      limitepacientes: number;
    }[];
    duracaoagendamentos: number;
    ativo: boolean;
    datavalidadeinicio?: string;
    datavalidadefim?: string;
    excecao: boolean;
    type_vacancy: boolean;
    dataexcecaoinicio?: string;
    dataexcecaofim?: string;
    dataatualizacao: string;
    clinica: IClinic;
  };
  profissional: {
    profile_imagem_urls: {
      original: string;
      x256: string;
      x720: string;
    };
    datacriacao: string;
    idusuario: string;
    nome: string;
    nomecomercial: string;
    login?: string;
    email: string;
    cpf: string;
    telefone: string;
    datanascimento: string;
    sexo: string;
    observacoes: string;
    assinaturamedico?: string;
    perfilprofissional: string;
    tipodocumentoprofissional: string;
    numerodocumentoprofissional: string;
    idademinima: number;
    idademaxima: number;
    atendemulheres: boolean;
    atendehomens: boolean;
    aceitaencaixe: boolean;
    retornomaximo: number;
    usoprontuario: boolean;
    cargos?: string;
    idcargo: string;
    idclinica: string;
    ativo: boolean;
    datalimiteacesso?: string;
    dataatualizacao: string;
    reset_password_token?: string;
    reset_password_expires?: string;
    assinaturamedico_url?: string;
  };
  valorbase: string;
  valoratual: string;
  valordesconto: string;
  valoracrescimo: string;
};

export type DependentType = {
  datacadastro: string;
  idpaciente: string;
  codigo: string;
  nomepaciente: string;
  datanascimento: string;
  datanascimentotexto: string;
  rg: string;
  descricaopacientefonte: string;
  sexo: string;
  telefoneresidencial: string;
  telefonecelular: string;
  nomeresponsavel: string;
  rgresponsavel: string;
  cpfresponsavel: string;
  telefoneresponsavel: string;
  nomeacompanhante: string;
  rgacompanhante: string;
  cpfacompanhante: string;
  telefoneacompanhante: string;
  ativo: boolean;
  identificador: string;
  idcanalatendimento: string;
  idcanalorigem: string;
};

export interface IDependentForm {
  nomepaciente: string;
  sexo: string;
  cpf: string;
  datanascimento: string;
  telefonecelular: string;
  telefoneresidencial: string;
  descricaopacientefonte: string;
  email: string;
  cpfresponsavel?: string;
  nomeresponsavel?: string;
}

export type PatientType = {
  datacadastro: string;
  idpaciente: string;
  codigo: string;
  nomepaciente: string;
  datanascimento: string;
  datanascimentotexto: string;
  rg: string;
  cpf: string;
  descricaopacientefonte: string;
  sexo: string;
  telefoneresidencial: string;
  telefonecelular: string;
  nomeresponsavel: string;
  rgresponsavel: string;
  cpfresponsavel: string;
  telefoneresponsavel: string;
  nomeacompanhante: string;
  rgacompanhante: string;
  cpfacompanhante: string;
  telefoneacompanhante: string;
  ativo: boolean;
  identificador: string;
  idcanalatendimento: string;
  idcanalorigem: string;
};

export type IServiceGroup = {
  datacriacao: Date;
  idgruposervico: string;
  nome: string;
  descricao: string;
  ativo: boolean;
  agendamentoonline: boolean;
  tipospagamentos: string;
  dataatualizacao: Date;
};

export interface Desconto {
  tipo: string;
  valor: number;
}

export interface Desconto2 {
  tipo: string;
  valor: number;
}

export interface IPromotionalItem {
  datacriacao: Date;
  iditempromocional: string;
  idcombo: string;
  iditemservico: string;
  informacoes?: string;
  desconto: Desconto2;
  ativo: boolean;
  grupopreparo?: string;
  preparo?: string;
  agendamentoativo: boolean;
  dataatualizacao: Date;
  itemservico: IServiceItem;
  itemsadicionais: IPromotionalItemAdditional[];
}

export interface IServicePackage {
  datacriacao: Date;
  idcombo: string;
  titulo: string;
  descricao?: string;
  idclinica?: string;
  tipospagamentos?: string;
  desconto: Desconto;
  valor: string;
  datavalidade: string;
  ativo: boolean;
  dataatualizacao: Date;
  informacoespaciente?: string;
  itenspromocionais: IPromotionalItem[];
}

export interface IPaymentMethod {
  idmetodopagamento: string;
  formapagamento:
    | "credito"
    | "debito"
    | "cheque"
    | "boleto"
    | "dinheiro"
    | "deposito"
    | "carteira"
    | "transferencia"
    | "convenio";
  nome: string;
  sigla: string;
  taxaadm: number;
  diaspracredito: number;
  parcelaminima: number;
  numeroparcelas: number;
  idcontabancaria: string;
  recebimentoantecipado: boolean;
  compensacaoautomatica: boolean;
  ativo: boolean;
  agendamentoonline: boolean;
  datacriacao: Date;
  dataatualizacao: Date;
}

export interface ServiceOrderStatement {
  total: string;
  discount: string;
  addition: string;
  valueDiscount: string;
  typeDiscount: string;
  currentValue: string;
  credit: number;
  totalPaid: number;
  balanceToPay: number;
}

export interface StatusHistorico {
  status: string;
  data: Date;
  idusuario: string;
  nomeusuario: string;
}

export interface IAppointment {
  idconsulta: string;
  idagenda: string;
  vacancy?: any;
  idpaciente: string;
  idprofissional: string;
  idusuario: string;
  data: Date;
  duracao: number;
  status: string;
  preferencial: boolean;
  prioritario: boolean;
  status_historico: StatusHistorico[];
  observacao?: any;
  idcanalatendimento: string;
  temretorno: boolean;
  disparo_confirmacao: boolean;
  idprestador?: any;
  iddesconto?: any;
  idacrescimo?: any;
  idresponsavel?: any;
  datacriacao: Date;
  dataatualizacao: Date;
}

export interface IServiceOrderItem {
  idorderitem: string;
  idordemservico: string;
  idagendamento: string;
  idpreordem: string;
  idguiaencaminhamento?: any;
  iditemservico: string;
  idusuario: string;
  status: "aberto" | "quitado" | "fechado" | "cancelado";
  quantidade: number;
  valorbase: string;
  valoratual: string;
  iddesconto: string;
  valordesconto: string;
  idacrescimo?: any;
  valoracrescimo: string;
  iditemplano?: any;
  idplano?: any;
  iditemcombo: string;
  idcombo: string;
  iditemorcamento?: any;
  idorcamento?: any;
  idpedidoexame?: any;
  idcheckin?: any;
  idprofissionalsolicitante?: any;
  datacriacao: Date;
  dataatualizacao: Date;
  itemservicos: IServiceItem;
  agendamento: IAppointment;
  checkin?: any;
  desconto: Desconto;
  combo?: IServicePackage;
  acrescimo?: any;
  itemplano?: any;
  idprofissional?: string;
  nomeprofissional: string;
}

export interface IPatient {
  datacadastro: string;
  idpaciente: string;
  codigo: string;
  nomepaciente: string;
  datanascimento: string;
  datanascimentotexto: string;
  rg: string;
  descricaopacientefonte: string;
  sexo: string;
  telefoneresidencial: string;
  telefonecelular: string;
  nomeresponsavel: string;
  rgresponsavel: string;
  cpfresponsavel: string;
  telefoneresponsavel: string;
  nomeacompanhante: string;
  rgacompanhante: string;
  cpfacompanhante: string;
  telefoneacompanhante: string;
  ativo: boolean;
  identificador: string;
  idcanalatendimento: string;
  idcanalorigem: string;
}

export interface IUser {
  idusuario: string;
  nome: string;
}

export interface Caixa {
  datacriacao: Date;
  idcaixa: string;
  nome: string;
  clinica: IClinic;
}

export interface IServiceOrder {
  idordemdeservico: string;
  codigo: string;
  idusuario: string;
  idpaciente: string;
  idclinica: string;
  valorbase: string;
  valoratual: string;
  status: string;
  fechado: boolean;
  valordesconto: string;
  valoracrescimo: string;
  idcaixa: string;
  datacriacao: Date;
  dataatualizacao: Date;
  prioritario: boolean;
  preferencial: boolean;
  ordemitemservico: IServiceOrderItem[];
  paciente: IPatient;
  metodopagamento: any[];
  usuario: IUser;
  caixa: Caixa;
  clinica?: IClinic;
}

export interface IServiceOrderResponse {
  serviceOrderStatement: ServiceOrderStatement;
  serviceOrder: IServiceOrder;
}

export interface IPaymentCardForm {
  cardNumber: string;
  cardOwnerName: string;
  expirationDate: string;
  securityCode: string;
  userDocument: string;
  documentType: string;
  phoneNumber: string;
}

export interface IPayServiceOrderResponse {
  payment_method?:
    | "credit"
    | "debit"
    | "check"
    | "bank_slip"
    | "cash"
    | "deposit"
    | "wallet"
    | "transfer"
    | "pix";
  status:
    | "started"
    | "processing"
    | "pending"
    | "approved"
    | "refused"
    | "refunded"
    | "chargeback"
    | "error";
  qr_code_url?: string;
  qr_code?: string;
  order_id?: string;
  expires_at?: Date;
}

export interface IAssociatedItem {
  id: string;
  iditemservico: string;
  idconveniocategoria: string;
  valor: number;
  repasseconvenio: {
    valor: number;
    tipo: "fixo" | "porcentagem";
  };
  itemservico: IServiceItem;
  servicoconvenioprofissionais: {
    id: string;
    idservicoconveniocategoria: string;
    idprofissional: string;
  }[];
  conveniocategoria: {
    id: string;
    idconvenio: string;
    nome: string;
    ativo: string;
    convenio: {
      idconvenio: string;
      nome: string;
      ativo: boolean;
      descontos: boolean;
    };
  };
}

export interface IHealthPlan {
  ativo: boolean;
  cnpj: string;
  dataatualizacao: Date;
  datacriacao: Date;
  endereco: string;
  idconvenio: string;
  nome: string;
  numerovidas: string;
  razaosocial: string;
  responsavel: string;
}

export interface ICategoryHealthPlan {
  ativo: boolean;
  idconvenio: string;
  nome: string;
  id: string;
  cnpj: string;
  dataatualizacao: Date;
  datacriacao: Date;
  convenio: IHealthPlan;
}

export interface IPromotionalItemAdditional {
  iditempromocionaladicional: string;
  iditemservico: string;
  itemservico: IServiceItem;
  iditempromocional: string;
  itempromocional: IPromotionalItem;
  dataatualizacao: Date;
  datacriacao: Date;
}
